/* Add application styles & imports to this file! */
body {
  --text-color: #222;
  --bkg-color: #F5F5F5;
  --btn-txt-color: white;
  --btn-bg-color: rgb(11, 88, 160);
}
body.dark-theme {
  --text-color: #eee;
  --bkg-color: #252222;
  --btn-txt-color: rgb(46, 43, 43);
  --btn-bg-color: rgb(20, 211, 195);
}

body {
  background: var(--bkg-color);
}

h1,
p {
  color: var(--text-color);
}

 $primary : #6246EA;
 $accent : #F5F5F5;
 $profit: #5BB317;;
 $loss: #FE0E0E