// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "./styles/varaibles";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$coincentric_user_UI-primary: mat.define-palette(mat.$indigo-palette);
$coincentric_user_UI-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$coincentric_user_UI-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$coincentric_user_UI-theme: mat.define-light-theme(
  (
    color: (
      primary: $coincentric_user_UI-primary,
      accent: $coincentric_user_UI-accent,
      warn: $coincentric_user_UI-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($coincentric_user_UI-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
@font-face {
  font-family: "cc-highlightFont";
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "cc-regularFont";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "cc-mediumFont";
  src: url("./assets/fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "cc-not-active";
  src: url("./assets/fonts/Poppins/Poppins-Thin.ttf");
}

.mat-simple-snackbar {
  font-size: 1.2em;
  color: white;
}

.snackbar-error {
  background: #fc2e20;
  border-radius: 15px !important;
}

.snackbar-success {
  background: #4ec33d;
  border-radius: 15px !important;
}

.snackbar-info {
  background: #65acf0;
  border-radius: 15px !important;
}

.backdropBackground {
  backdrop-filter: blur(4px);
  background: rgba(125, 123, 145, 0.2);
}

//scroll-bar CSS
::-webkit-scrollbar {
  width: 4px;
  overflow-y: scroll;
  background: grey;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}
